import * as React from "react"
import { Container } from "react-bootstrap"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="About Julia Myers" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>
        About Julia Myers
        </h1>
       
      </Container>
    </div>
    <Container className="singleCol two-rem-padding">

      <p className="float-md-start float-none px-3 px-md-0 text-center text-md-start me-0 me-md-4">
        <StaticImage
          src="../images/julia-myers-new.jpg"
          loading="eager"
          width={350}
          quality={65}
          formats={["auto", "webp", "avif"]}
          alt="photo of Julia Myers"
        />
      </p>
      
      <p>
        Julia A. Myers, M.A. has successfully completed the esteemed Council of Parent Attorneys and Advocates’ (COPAA) Special Education and Advocacy Training (SEAT) 1.0 course. She is also a Certified Dyslexia Advocate through the Dyslexia Training Institute (DTI).
      </p>
      <p>
        Julia provides consultation services to parents of dyslexic learners throughout the Bay Area. Navigating the special education eligibility process at local public schools can be challenging. Julia helps parents demystify this overwhelming process and empowers them to support their child's needs. Julia adheres to COPAA’s Advocate Voluntary Code of Ethics.
      </p>
      <p>
        Julia is a Certified Slingerland® Teacher (CST) by the Slingerland Literacy Institute (SLI) and a Certified Dyslexia Practitioner through the Center for Effective Reading Instruction (CERI). She has been delivering Structured Literacy instruction to students with dyslexia using the Slingerland® approach since 2011 in classroom settings and in 1:1 tutoring. In addition, Julia is trained in many other remediation programs including Structured Word Inquiry (SWI) and Project Read®, and regularly incorporates those methodologies into her instruction.
      </p>
      <p>
        To keep current on educational laws and regulations, Julia belongs to several professional educational advocacy organizations including the International Dyslexia Association and the Council of Parent Attorneys and Advocates. She avidly consumes the latest information about special education, educational laws and regulations, advocacy, and literacy issues.
      </p>
      <p>
        Julia is a non-attorney, special educational advocate. She does not provide legal advice.
      </p>

      <p><strong>Professional Memberships</strong></p>
      <ul className="list-unstyled">
        <li><a href="https://www.slingerland.org">Slingerland Literacy Institute (SLI)</a></li>
        <li><a href="https://dyslexiaida.org">International Dyslexia Association (IDA)</a></li>
        <li><a href="https://www.copaa.org">Council of Parent Attorneys and Advocates (COPAA)</a></li>
      </ul>
      
    </Container>
  </Layout>
)

export default IndexPage
